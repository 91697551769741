var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        staticClass: "input-max-width",
        attrs: {
          dense: "",
          "hide-details": "",
          items: _vm.filteredDaysOptions,
          outlined: "",
          label: "Filter " + _vm.label + " from",
          color: "primary",
          disabled: _vm.disabled
        },
        model: {
          value: _vm.days,
          callback: function($$v) {
            _vm.days = $$v
          },
          expression: "days"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }