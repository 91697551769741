<template>
  <div>
    <v-select
      v-model="days"
      dense
      hide-details
      :items="filteredDaysOptions"
      outlined
      :label="`Filter ${label} from`"
      class="input-max-width"
      color="primary"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  props: {
    label: {
      type: String,
      default: 'Dates'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      daysOptionsShort: [
        { text: '1 Week', value: -7 },
        { text: '1 Month', value: -31 }
      ],
      daysOptionsLong: [
        { text: '3 Months', value: -90 },
        { text: '6 Months', value: -180 },
        { text: '12 Months', value: -365 },
        { text: '15 Months', value: -456 },
        { text: '18 Months', value: -547 },
        { text: '24 Months', value: -732 }
      ]
    }
  },

  computed: {
    ...mapGetters(['isViewAllCustomers']),
    days: {
      get () {
        return this.$store.getters.daysFromNow()
      },
      set (value) {
        this.$store.dispatch('setDaysFromNow', value)
      }
    },
    filteredDaysOptions () {
      return [...this.daysOptionsShort, ...this.daysOptionsLong] // return this.isViewAllCustomers ? this.daysOptionsShort : [...this.daysOptionsShort, ...this.daysOptionsLong]
    }
  }
}
</script>
